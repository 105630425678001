.buttonStyle {
  margin: 8px 10px 12px 0;
}
.formStyle {
  height: 100%;
  overflow-y: auto;
}
.formcControlLabelStyle {
  margin: 8px 0 0;
}

.heading {
  font-weight: bold;
}

.deviceLogo {
  position: relative;
  height: 100px !important;
  width: 100px;
  margin: 0px 10px 10px 0px;
}
.container {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.header {
  position: relative;
}
.body {
  flex: 1;
}
