html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.listMargins {
  margin-top: 30px;
  margin-bottom: 30px;
}
.deviceData {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabContent {
  height: 100%;
  overflow: hidden;
}

.tabpanel {
  height: 100%;
}

.tabPannelControl {
  height: 100%;
}

.chartContainer {
  height: 100%;

  overflow: hidden;
}

.tripContainer {
  height: 100%;
  overflow: hidden;
}

.tableBody {
  height: 100%;
  overflow: auto;
}

.ignitionChart {
  height: 25%;
}
.powerChart {
  height: 75%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

fieldset {
  border: 1px solid #c3adad;
  border-radius: 5px;
  margin-top: 10px;
  padding-top: 0px;
}
legend {
  /* For better legibility against the box-shadow */
}

.fieldSetInfoGreen {
  margin-top: 10px;
  padding-top: 10px;
  background-color: rgb(178, 247, 178);
}

.fieldSetInfoRed {
  margin-top: 10px;
  padding-top: 10px;
  background-color: rgb(253, 108, 108);
}

.fieldSetInfo {
  clear: both;
}
