.deviceLogo {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px !important;
  width: 40px;
  margin: 0px 0px 10px 0px;
}

.heading {
  font-weight: bold;
  font-size: 0.8em;
  position: absolute;
  top: 0;
  left: 50px;
}
.contact {
  font-weight: normal;
  font-size: 1em;
}

.group {
  position: absolute;
  font-size: 0.7em;
  top: 16px;
  left: 50px;
}

.lastUpdate {
  position: absolute;
  font-size: 0.7em;
  top: 30px;
  left: 50px;
}

.battery {
  position: absolute;
  font-size: 0.7em;
  top: 30px;
  right: 5px;
}

.showAddress {
  position: absolute;
  font-size: 0.7em;
  top: 44px !important;
  left: 3px;
}
