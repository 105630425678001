.MapSelectorBox {
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 10px;

  width: 88px !important;
  height: 88px !important;

  border-width: 2px;
  border-radius: 8px;
  border-color: lightgrey;
  border-style: solid;
  text-align: center;
}
