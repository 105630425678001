.App {
  position: absolute;
  padding: 0 !important;
  top: 0px;
  width: 100%;
  height: 100%;
}

.mapHolder {
  width: 100%;
  height: 100%;
}

.DeviceListContainer {
  height: 100%;
  display: flex;
  padding: 0;
  flex-direction: column;
}

.Devicelist {
  flex: 1;
}

.DeviceListItem {
  font-weight: normal;
}
.DeviceListItem:selected {
  font-weight: bold;
}
.DeviceListItem:not:selected {
  font-weight: normal;
}

.DeviceListItem #name {
  font-weight: bold;
  font-size: larger;
  position: absolute;
  top: 0;
  left: 50px;
}
.DeviceListItem #lastUpdate {
  position: absolute;
  bottom: 0;
  left: 0;
}

.DeviceListItem #deviceAvatarActive,
#deviceAvatarInactive {
  position: absolute;
  top: 5px;
  left: 5px;
}
