.map_wrap {
  position: relative;
  width: 100%;
  height: 100%; /* calculate height of the screen minus the heading */
  z-index: 1;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.tooltip {
  position: absolute;
  z-index: 100;
  padding: 10px;
  background-color: azure;
}
.col1 {
  width: 90px;
  float: left;
}
.col2 {
  width: 90px;
  float: left;
}
