.paperStyle {
  padding: 30px;
  width: 280px;
  margin: 20px auto;
}

.buttonStyle {
  margin: 8px 0 12px 0;
}

.formcControlLabelStyle {
  margin: 8px 0 0;
}

.signUpLinkStyle {
  margin: 5px;
}
